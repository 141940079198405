if (document.getElementById("add-row")) {
  const productTable = document.querySelector(".product--table");
  const orderForm = document.getElementById("teilnahme");
  const orderFormSubmit = document.getElementById("teilnahme-submit");
  const error = document.getElementById("error");

  let index = 2;

  const deleteRow = element => {
    element.parentNode.parentNode.remove();
  };

  const addRow = () => {
    let output = `<div class="product--table-row">
                        <div class="table--item">
                        <select name="geraet-${index}" id="geraet-${index}">
                            <option selected disabled>-- Gerät auswählen --</option>
                            <option value="Opn S 1">Opn S 1</option>
                            <option value="Opn S 2">Opn S 2</option>
                            <option value="Opn S 3">Opn S 3</option>
                        
                        </select>
                        </div>
                        <div class="table--item">
                        <select name="bauform-${index}" id="bauform-${index}" >
                            <option selected disabled>-- Bauform auswählen --</option>
                            <option value="Ex-Hörer Mini-R (Akku)">Ex-Hörer Mini-R (Akku)</option>
                            <option value="Ex-Hörer Mini">Ex-Hörer Mini</option>
                            <option value="Ex-Hörer-Mini-T">Ex-Hörer-Mini-T</option>
                            <option value="HdO 105">HdO 105</option>
                        </select>
                        </div>
                        <div class="table--item">
                        <select name="farben-${index}" id="farben-${index}">
                            <option selected disabled>-- Farbe auswählen --</option>
                            <option value="CO90 Quarzsand">CO90 Quarzsand</option>
                            <option value="CO94 Sienna-Braun">CO94 Sienna-Braun</option>
                            <option value="CO93 Nussbraun">CO93 Nussbraun</option>
                            <option value="CO61 Perl-Schwarz">CO61 Perl-Schwarz</option>
                            <option value="CO92 Titan">CO92 Titan</option>
                            <option value="CO91 Platin">CO91 Platin</option>
                            <option value="CO44 Silberweiß">CO44 Silberweiß</option>   
                        </select>
                        </div>
                        <div class="table--item"><input type="number" min="1" max="20" name="menge-${index}" id="menge-${index}"  placeholder="Menge"></div>
                        <div class="table--item">
                        <button id="delete-row" onclick="deleteRow(this)"><img src="assets/img/icon--trash.svg"></button>
                        <button id="add-row" onclick="addRow()">+</button>
                </div>
                    </div>`;
    productTable.insertAdjacentHTML("beforeend", output);
    index++;
  };

  const checkIdo = element => {
    element.value === "HdO 105"
      ? element.parentNode.parentNode
          .querySelector('select[id*="farben-"]')
          .classList.add("disabled")
      : element.parentNode.parentNode
          .querySelector('select[id*="farben-"]')
          .classList.remove("disabled");
  };

  const formSubmission = e => {
    e.preventDefault();
    let sum = 0;
    let sumS1 = 0;
    const productRow = document.querySelectorAll(".product--table-row");

    productRow.forEach(row => {
      let bauform = row.querySelector("select[id*='geraet-']").value;
      let menge = row.querySelector("input[id*='menge-']").value;
      bauform === "Opn S 1" ? (sumS1 += parseFloat(menge)) : null;
      sum += parseFloat(menge);
    });
    let missing = 0;
    let missingS1 = 0;
    let msg = "";
    if (sumS1 === 0 && sum < 4) {
      missing = 4 - sum;
      msg = `<h4>Für die Teilnahme müssen Sie noch ${missing}x ein beliebiges Opn S abnehmen</h4>`;
    } else if (sumS1 < 2 && sum < 4) {
      missing = 4 - sum;
      missingS1 = 2 - sumS1;
      msg = `<h4>Für die Teilnahme müssen Sie noch ${missingS1}x Opn S 1 oder ${missing}x ein beliebiges Opn S abnehmen</h4>`;
    } else if (sumS1 < 2 && sum === 0) {
      missingS1 = 2 - sumS1;
      msg = `<h4>Für die Teilnahme müssen Sie noch ${missingS1} Opn S 1 oder ${4 -
        missingS1}weitere beliebiges Opn S abnehmen</h4>`;
    }
    sum >= 4 || sumS1 >= 2 ? (error.innerHTML = "") : (error.innerHTML = msg);

    if (
      msg === "" &&
      sum + sumS1 > 0 &&
      orderForm.firma.value.length > 1 &&
      orderForm.ansprechpartner.value.length > 1 &&
      orderForm.plz.value.length > 1 &&
      orderForm.ort.value.length > 1 &&
      orderForm.consent.checked &&
      orderForm.emailhp.value === ""
    ) {
      orderForm.submit();
    } else if (
      orderForm.firma.value.length < 1 ||
      orderForm.ansprechpartner.value.length < 1 ||
      orderForm.plz.value.length < 1 ||
      orderForm.ort.value.length < 1 ||
      !orderForm.consent.checked
    ) {
      error.innerHTML = "<h4>Bitte alle Felder ausfüllen</h4>";
    }
  };

  const handleChange = e => {
    if (e.target.value === "HdO 105") {
      e.target.value === "HdO 105"
        ? e.target.parentNode.parentNode.parentNode
            .querySelector('select[id*="farben-"]')
            .classList.add("disabled")
        : e.target.parentNode.parentNode.parentNode
            .querySelector('select[id*="farben-"]')
            .classList.remove("disabled");
    }

    e.target.id === "delete-row" || e.target.id === "add-row"
      ? e.preventDefault()
      : null;
  };

  orderFormSubmit.addEventListener("click", formSubmission);
  window.addEventListener("click", handleChange);
}
