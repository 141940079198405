const nav = document.querySelector("nav");

const headerScroll = () => {
  const st = window.pageYOffset;
  st > 60 ? nav.classList.add("scrolled") : nav.classList.remove("scrolled");
  st > 60
    ? document.body.classList.add("scrolled")
    : document.body.classList.remove("scrolled");
};

//$(window).scroll(debounce(headerScroll, 0, 20));

window.addEventListener("scroll", debounce(headerScroll, 0, 20));

//============================================
// Intersectionobserver
//============================================

let slide = document.querySelectorAll(".slide--in");

if (typeof IntersectionObserver === "undefined") {
  console.log("=== INTERSECTION OBSERVER NOT AVAILABLE ===");
} else {
  console.log("START INTERSECTION OBSERVER");
  const slideOptions = {
    threshold: [0, 0.3, 0.5, 0.7, 1.0]
  };

  const slideIn = new IntersectionObserver((entries, slideIn) => {
    entries.forEach(entry => {
      if (!entry.isIntersecting) {
        entry.target.classList.add("out");
        return;
      } else {
        entry.intersectionRatio < 0.2
          ? entry.target.classList.add("out")
          : entry.target.classList.remove("out");
      }
    });
  }, slideOptions);

  slide.forEach(image => {
    slideIn.observe(image);
  });
}
