const burger = document.getElementById("burger");
const nav = document.querySelector(".main--nav");
const navItem = document.querySelectorAll(".main--nav-item");

burger.addEventListener("click", e => {
  document.body.classList.toggle("active");
});

navItem.forEach(nav => {
  nav.addEventListener("click", e => {
    document.body.classList.remove("active");
    navItem.forEach(i => i.classList.remove("active"));
    nav.classList.add("active");
  });
});
